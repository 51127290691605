<template>
  <!-- 设备管理 -->
  <div class="EquipmentManger">
    <div style="margin-top: 10px">
      <span>设备编码：</span>
      <el-input
        type="text"
        placeholder="请输入设备编码"
        v-model="textarea"
        style="width: 300px"
        :disabled="disabledtext"
      >
      </el-input>

      <span class="demonstration" style="margin: 0 0px 0 30px">日期：</span>
      <el-date-picker
        v-model="datevalue"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="datechange"
        :disabled="disabledtext"
      ></el-date-picker>
      <el-button type="primary" size="mini" style="margin-left: 10px" @click="searcht()" v-if="search">查询</el-button>
      <el-button type="warning" style="margin-left: 30px" @click="tuisearch()" v-else>取消搜索</el-button>
    </div>

    <div class="caozuo">
      <div>
        <el-button
          type="primary"
          sizse="mini"
          @click="
            dialogFormVisible = true;
            type = 'add';
            form = {};
            is_use = '';
            address_id = '';
          "
          >添加设备</el-button
        >&emsp;
        <span>共&nbsp;{{ pagetotal }}&nbsp;台设备</span>
      </div>
    </div>
    <el-table
      border
      :data="tableData"
      style="
        width: 100%;
        margin-top: 20px;
        max-height: 65vh;
        overflow-y: scroll;
      "
    >
      <el-table-column label="设备号" prop="device_number" width="200"></el-table-column>
      <el-table-column label="设备详情" prop="device_info" width="300px"></el-table-column>
      <el-table-column label="设备场地" prop="address_name" width="300px"></el-table-column>
      <el-table-column label="设备标签" prop="device_tags"></el-table-column>
      <el-table-column label="设备使用次数" prop="device_log_count"></el-table-column>
      <el-table-column label="耗电量" prop="power_consumption"></el-table-column>
      <el-table-column label="是否使用">
        <template #default="scope">
          <el-switch v-model="scope.row.is_use" active-color="#13ce66" @change="switchisuse(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <span>{{ scope.row.status == 1 ? "正常" : "故障" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备端口状态" prop="port_number"></el-table-column>
      <!-- <el-table-column
        label="设备添加时间"
        prop="created_at"
        width="200px"
      ></el-table-column> -->
      <el-table-column label="操作" width="300px">
        <template #default="scope">
          <el-button type="primary" size="small" @click="$router.push('/equipmentports?id=' + scope.row.id)"
            >点击查看详情
          </el-button>
          <el-button type="danger" size="small" @click.prevent="deleteRow(scope.$index, tableData)">删除</el-button>
          <el-button type="primary" size="small" @click="handleClick(scope.row, $index)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="dialogFormVisible" width="30%" :title="type == 'add' ? '添加设备' : '编辑设备'">
      <el-form :model="form">
        <el-form-item label="设备号" :label-width="formLabelWidth">
          <el-input v-model="form.device_number"></el-input>
        </el-form-item>
        <el-form-item label="设备详情" :label-width="formLabelWidth">
          <el-input v-model="form.device_info"></el-input>
        </el-form-item>
        <el-form-item label="设备场地" :label-width="formLabelWidth">
          <el-select v-model="form.address_id" placeholder="场地状态">
            <el-option v-for="item in address_list" :key="item.id" :label="item.address_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="	设备标签" :label-width="formLabelWidth">
          <el-input v-model="form.device_tags"></el-input>
        </el-form-item>

        <el-form-item label="使用情况" :label-width="formLabelWidth">
          <el-select v-model="form.is_use" placeholder="场地状态">
            <el-option label="使用中" value="1"></el-option>
            <el-option label="不可使用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备端口数" :label-width="formLabelWidth">
          <el-input v-model="form.port_number"></el-input>
        </el-form-item>
        <el-form-item label="设备绑定SN号" :label-width="formLabelWidth">
          <el-input v-model="form.sn" type="number"></el-input>
        </el-form-item>
        <el-form-item label="跳转分组" :label-width="formLabelWidth">
          <el-select v-model="form.jump_group_id" placeholder="跳转分组">
            <el-option v-for="item in jump_group_list" :key="item.id" :label="item.jump_group_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              dialogFormVisible = false;
              form = {};
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="addcurse()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import { deviceAdd, deviceList, deviceDel, deviceEdit } from "../../request/api";
import { ElMessage } from "element-plus";
import Moment from "moment";
export default {
  data() {
    return {
      activeIndex: "1", //顶部菜单
      textarea: ref(""),
      disabled: "1",
      tableData: [],
      tableDataCopy: [],
      disabledtext: false,
      dialogFormVisible: false,
      formLabelWidth: "110px",
      search: true,
      datevalue: [],
      jump_group_list: [],
      form: {
        device_number: null,
        device_info: null,
        device_tags: null,
        address_id: null,
        port_number: null,
        is_use: null,
        sn: "",
        jump_group_name: "",
        jump_group_id: "",
        jump_group_https: "",
      },
      type: "add", //判断是增加还是修改
      address_list: "", //场地
      pagetotal: "",
    };
  },
  props: ["page", "pagepage"],
  watch: {
    page() {
      this.tableData == this.tableDataCopy ? this.device() : this.searcht();
    },
    pagepage() {
      this.tableData == this.tableDataCopy ? this.device() : this.searcht();
    },
  },
  created() {
    this.device();
  },
  methods: {
    switchisuse(item) {
      this.form.device_number = item.device_number;
      this.form.device_info = item.device_info;
      this.form.address_id = item.address_id;
      this.form.device_tags = item.device_tags;
      this.form.is_use = item.is_use == true ? "1" : "0";
      this.form.port_number = item.port_number;
      this.form.sn = item.sn;
      this.form.id = item.id;
      deviceEdit(this.form).then((res) => {
        if (res.code == 200) {
          ElMessage.success({
            message: res.msg,
            type: "success",
          });
          this.device();
          this.form = {};
          this.dialogFormVisible = false;
        } else {
          ElMessage.error(res.msg);
        }
      });
    },
    // 下载图片
    downloadCodeImg(src) {
      var a = document.createElement("a");
      a.download = name || "pic";
      // 设置图片地址
      a.href = src;
      a.click();
    },
    searcht() {
      this.disabledtext = true;
      this.search = false;
      deviceList({
        page: this.page,
        page_size: this.pagepage,
        keyword: this.textarea,
        start_time: this.datevalue ? Moment(this.datevalue[0]).format("YYYY-MM-DD HH:mm:ss") : "",
        end_time: this.datevalue ? Moment(this.datevalue[1]).format("YYYY-MM-DD HH:mm:ss") : "",
      }).then((res) => {
        this.$emit("pagetotalClick", res.total);
        this.tableData = res.list.device_list;
        this.tableData.forEach((item) => {
          item.is_use = item.is_use == 1 ? true : false;
        });
      });
    },
    tuisearch() {
      this.search = true;
      this.disabledtext = false;
      this.textarea = "";
      this.datevalue = [];
      this.device();
    },
    device() {
      return new Promise((resolve, reject) => {
        deviceList({ page: this.page, page_size: this.pagepage }).then((res) => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.total);
          this.pagetotal = res.total;
          this.address_list = res.list.address_list;
          this.tableData = res.list.device_list;
          this.jump_group_list = res.list.jump_group_list;
          this.tableData.forEach((item) => {
            item.is_use = item.is_use == 1 ? true : false;
          });
          this.tableDataCopy = this.tableData;
        });
      });
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
    addcurse() {
      this.jump_group_list.forEach((item) => {
        if (this.form.jump_group_id == item.id) {
          this.form.jump_group_https = item.jump_group_https;
          this.form.jump_group_name = item.jump_group_name;
        }
      });
      if (this.form.sn) {
        if (this.type == "add") {
          deviceAdd(this.form).then((res) => {
            if (res.code == 200) {
              ElMessage.success({
                message: res.msg,
                type: "success",
              });
              this.device();
              this.form = {};
              this.dialogFormVisible = false;
            } else {
              ElMessage.error(res.msg);
            }
          });
        } else if (this.type == "revise") {
          this.form.is_use = this.form.is_use == "使用中" ? 1 : this.form.is_use == "不可使用" ? 0 : this.form.is_use;
          deviceEdit(this.form).then((res) => {
            if (res.code == 200) {
              ElMessage.success({
                message: res.msg,
                type: "success",
              });
              this.device();
              this.form = {};
              this.dialogFormVisible = false;
            } else {
              ElMessage.error(res.msg);
            }
          });
        }
      } else {
        ElMessage.error("请将信息输入完整");
      }
    },
    handleClick(item, index) {
      this.dialogFormVisible = true;
      this.form.device_number = item.device_number;
      this.form.device_info = item.device_info;
      this.form.address_id = item.address_id;
      this.form.device_tags = item.device_tags;
      this.form.is_use = item.is_use == 0 ? "不可使用" : "使用中";
      this.form.port_number = item.port_number;
      this.form.sn = item.sn;
      this.form.id = item.id;
      this.form.jump_group_id = item.jump_group_id;
      this.type = "revise";
    },
    // 删除
    deleteRow(index, rows) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deviceDel({ id: rows[index].id }).then((res) => {
            if (res.code == 200) {
              this.device();
              rows.splice(index, 1);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-textarea__inner {
  height: 50px !important;
}

/deep/.cell {
  text-align: center;
  color: #000;
}

.caozuo {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

img {
  width: 80px;
  height: 80px;
}
</style>
